import React, { useState, useEffect, useContext } from 'react';
import { useFormikContext } from 'formik';
import { FormattedMessage, IntlContext } from 'react-intl';
import { SelectFieldWithLabel, TextAreaFieldWithLabel } from './FormFields';
import { getConstants } from '../constants';

const PeopleFields = () => {
  const { values, setFieldValue, handleChange, initialValues, setFieldTouched } = useFormikContext();
  const [thirdOptions, setThirdOptions] = useState([]);
  const { messages } = useContext(IntlContext);
	const { statusInCanadaOptions, topicOptions, delayedShipmentOptions, importingOptions, exportingOptions, travelProgramsOptions } = getConstants(messages);

  const getThirdOptions = (topic) => {
    switch (topic) {
        case 'Delayed Shipment [topic=delayedShipment]':
            return delayedShipmentOptions;
        case 'Importing [topic=importing]':
            return importingOptions; 
        case 'Exporting [topic=exporting]':
            return exportingOptions; 
        case 'Travel Programs [topic=travelPrograms]':
            return travelProgramsOptions;
        case 'Currency [topic=currency]':
        case 'Refunds and Appeals [topic=refundsAndAppeals]':
        default:
            return [];
    }
};

  useEffect(() => {
    const newThirdOptions = getThirdOptions(values.topic);
    setThirdOptions(newThirdOptions);  // Update the state to reflect these new options.
  }, [values.topic]);

  useEffect(() => {
    // Reset fields if topic gets changed to keep things cleaner/less complicated
    const peopleFields = ["statusInCanada" , "topic" ,"thirdTopic" ,"detailedDescription"];
    peopleFields.forEach(field => {
      setFieldValue(field, initialValues[field])
      setFieldTouched(field, false)
    })
  }, [values.questionTopic]);

  useEffect(() =>{
    if (['Currency [topic=currency]',
      'Refunds and Appeals [topic=refundsAndAppeals]'].includes(values.topic)) {
        setFieldValue('thirdTopic', '');
        setFieldTouched('thirdTopic', false);
      } else {
          setFieldTouched('thirdTopic', false);
      }
  }, [values.topic, setFieldValue, setFieldTouched]);
  
  return (
        <fieldset className="col-md-12">
          <SelectFieldWithLabel
            id="statusInCanada"
            name="statusInCanada"
            label={messages["contact.i18n-statusInCanada"]}
            required={true}
            options={[{ value: "" }, ...statusInCanadaOptions]}
          />
      
          <SelectFieldWithLabel
            id="topic"
            name="topic"
            label={messages["contact.i18n-topic"]}
            required={true}
            options={[{ value: "" }, ...topicOptions]}
            onChange={handleChange}
          />
      
          {thirdOptions.length > 0 && (
            <SelectFieldWithLabel
              id="thirdTopic"
              name="thirdTopic"
              data-testid="thirdTopic"
              label={ messages["contact.i18n-topic-secondary-topic-label"] }
              required={true}
              options={[{ value: ""},...thirdOptions,]}
              onChange={handleChange}
            />
          )}
          {values.topic && 
            <TextAreaFieldWithLabel
              id="detailedDescription"
              name="detailedDescription"
              label={` ${messages["contact.i18n-pleaseProvideADetailedDescriptionOfYourRequestForAssistance"]} (${messages["contact.i18n-pleaseProvideADetailedDescriptionOfYourRequestForAssistance-restrictions"]}): `}
              required={true}
              wrapperClassName={{}}
              maxLength="3000"
              numOfRows="4"
            />
          }
        </fieldset>
  );
};

export default PeopleFields;
