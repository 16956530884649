import React, { useContext, useState, useEffect, useRef } from 'react';
import {FormattedMessage, IntlContext} from 'react-intl';
import axios from 'axios';
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import { SelectFieldWithLabel, TextFieldWithLabel, TextAreaFieldWithLabel, CheckboxWithLabel, DateTimeFieldWithLabel } from '../../../components/FormFields';
import { getConstants, FormTypes } from '../../../constants';
import ProvinceStateDropdown from '../../../components/ProvinceStateDropdown';
import ThirdPartyRepDetails from '../../../components/ThirdPartyRepDetails';
import EmployeeDetailsDynamicRow from '../../../components/EmployeeDetailsDynamicRow';
import WitnessDetailsDynamicRow from '../../../components/WitnessDetailsDynamicRow';
import ReferenceNumberDetails from '../../../components/ReferenceNumberDetails';
import TypeOfComplaint from '../../../components/TypeOfComplaint';
import PreviouslyReportedComplaint from '../../../components/PreviouslyReportedComplaint';
import Spinner from '../../../components/Spinner';
import { postConfigHeader, scrollToFirstError, getElementValue, getElement } from '../../../utils/formUtils';

import { complaint } from '../../formFiller';

import './complaint.css';

//Get today's date in yyyy-mm-ddThh:mm for datepicker
const now = new Date();
const offset = now.getTimezoneOffset() * 60000; // Offset in milliseconds
const today = new Date(now - offset).toISOString().slice(0, 19); //

const telephoneRegexp = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/


export default function ComplaintForm({navigation}) {
    const [showSpinner, setShowSpinner] = useState(false);
	const { messages } = useContext(IntlContext);
    const constants = getConstants(messages);
    const setFlashError = navigation?.setFlashError;
    const formikSetFieldValue = useRef(null);

	const clientLanguage = constants["clientLanguage"];
    const clientCommunicationMethod = constants["clientCommunicationMethod"];
	const countryComplaint = constants["countryComplaint"];
	const locationOfIncident = constants["locationOfIncident"];
	const locationType = constants["locationType"];
	const typeOfEvidence = constants["typeOfEvidence"];

    useEffect(() => {
		setFlashError(null);
        window.scrollTo(0, 0); // Workaround for screen rendering at the bottom of the page
	},[])

    function handleSubmitComplaint(values, resetForm) {
         console.debug('VALUES', values);

        // Figure out which province/state field value to use
        const provinceStateComplaintParsed = values.provinceComplaint || values.stateComplaint || values.provinceStateComplain;
        const provinceStateComplaintRepParsed = values.provinceStateComplaintRep || values.provinceComplaintRep || values.stateComplaintRep;

		let submitObj = {
            ...values,
            nirobat: "555", //additional field for bot/spam protection

            // Figure out which province/state field to pass and display for confirmation
            provinceStateComplaintParsed,
            provinceStateComplaintRepParsed
        }
         console.debug('SUBMIT OBJ', submitObj);

        //Post form
		axios.post(window.env.apiUrlPrefix + "/complaint", submitObj, postConfigHeader).then(function(response){
			// console.debug("Response status:", response?.status, response);
            // console.log(submitObj);

            const confirmObj = createConfirmObject(values);
            // console.debug('CONFIRM OBJ', confirmObj);
            const confirmationPreamble = <>
                <h2>{messages['confirmation']}</h2>
                <div className="preamble-section">{messages['contact.i18n-confirm-complaint-preamble1']}</div>
                <div className="preamble-section">{messages['contact.i18n-confirm-complaint-preamble2']}</div>
                <div className="preamble-section">{messages['contact.i18n-confirm-complaint-preamble3']}</div>
                <div className="preamble-section">{messages['contact.i18n-confirm-complaint-preamble4']}</div>
            </>
			resetForm();

            navigation.navigate("Results", { results: confirmObj, preamble: confirmationPreamble, form: FormTypes.COMPLAINT});
		}).catch(function(error){
			console.error("ERROR POSTING COMPLAINT: " + error);
            setFlashError({duration:-1, messages:[`${messages['http.general']} (${error.code})`]})
            setShowSpinner(false)
        })
	}

    function createConfirmObject(values) {

        // Figure out which province/state field label to use for confirmationpage
        const provinceStateComplaintId = getElement('provinceComplaint')?.id || getElement('stateComplaint')?.id || getElement('provinceStateComplaint')?.id;
        const provinceStateComplaintRepId = getElement('provinceComplaintRep')?.id || getElement('stateComplaintRep')?.id || getElement('provinceStateComplaintRep')?.id;

        //List items
        const refItems = values['referenceItem'].map(ref => ({
            data: `${ref.referenceTypeLabel} - ${ref.referenceNumber}`,
            label: messages['references']
        }));
        const employeeItems = values['employeeDetails'].map(emp => (
            {
                label: messages['employee-details'],
                data: `
                    ${messages['first-employee']} - ${emp.employeeFirstName}
                    ${messages['last-employee']} - ${emp.employeeLastName}
                    ${messages['badge']} - ${emp.employeeBadgeNumber}
                    ${messages['email']} - ${emp.employeeEmail}
                    ${messages['description']} - ${emp.employeeDescription}
                `,
            }
        ));
        const witnessItems = values['witnessDetails'].map(wit => (
            {
                label: messages['witness-details'],
                data: `
                    ${messages['first-witness']} - ${wit.witnessFirstName}
                    ${messages['last-witness']} - ${wit.witnessLastName}
                    ${messages['telephone']} - ${wit.witnessTelephone}
                    ${messages['email']} - ${wit.witnessEmail}
                `,
            }
        ));

        const confirmationObj = {
            contact: {
                label: messages['contact-information'],
                data: [
                    { data: values['firstNameComplaint'], label: messages['CIPS.i18n-firstName']},
                    { data: values['firstNameComplaint'], label: messages['CIPS.i18n-lastName']},
                    { data: getElementValue('clientCommunicationMethod'), label: messages['CIPS.i18n-communicationMethod-label']},
                    { data: getElementValue('clientLanguageComplaint'), label: messages['CIPS.i18n-languagePreference-label']},
                    { data: values['emailComplaint'], label: messages['email']},
                    { data: values['phoneNumberComplaint'], label: messages['telephone-number']},
                ]
            },
            mailing: {
                label: messages['mailing-address'],
                data : [
                    { data: values['address1Complaint'], label: messages['address-street-1']},
                    { data: values['address2Complaint'], label: messages['address-street-2']},
                    { data: getElementValue('countryComplaint'), label: messages['CIPS.i18n-country-complaint']},
                    { data: getElementValue(provinceStateComplaintId), label: messages['CIPS.i18n-province-state-label']},
                    { data: values['cityComplaint'], label: messages['city']},
                    { data: values['postalCodeComplaint'], label: messages['postalzip-code']},
                ]
            },
            thirdParty: values["thirdPartyAuthorized"][0] !== "yes" ? null :
                {
                    label: messages['third-party'],
                    data: [
                        { data: values['firstNameComplaintRep'], label: messages['CIPS.i18n-firstName']},
                        { data: values['familyNameComplaintRep'], label: messages['CIPS.i18n-lastName']},
                        { data: values['firmOrganizationComplaint'], label: messages['firmorganization']},
                        { data: getElementValue('clientCommunicationMethodRep'), label: messages['CIPS.i18n-communicationMethod-label']},
                        { data: getElementValue('clientLanguageComplaintRep'), label: messages['CIPS.i18n-languagePreference-label']},
                        { data: getElementValue('orderCommunicationRep'), label: messages['CIPS.i18n-communicationOrder']},
                        { data: values['complaintEmailRep'], label: messages['email']},
                        { data: values['phoneNumberComplaintRep'], label: messages['telephone-number']},
                        { data: values['address1ComplaintRep'], label: messages['address-street-x-x']},
                        { data: values['cityComplaintRep'], label: messages['city']},
                        { data: getElementValue('countryComplaintRep'), label: messages['CIPS.i18n-country-complaint']},
                        { data: getElementValue(provinceStateComplaintRepId), label: messages['CIPS.i18n-province-state-label']},
                        { data: values['postalCodeComplaintRep'], label: messages['postalzip-code']},
                    ]
                }
            ,
            details: {
                label: messages['details'],
                data: [
                    { data: getElementValue('typeOfComplaint'), label: messages['type-of-complaint']},
                    { data: values['disabilityAccomodations'], label: messages['accomodations']},
                    { data: getElementValue('previousComplaint'), label: messages['is-this-a-previously-reported']},
                    { data: values['previousComplaintReference'], label: messages['previous-complaint']},
                    { data: values['serviceProvider'], label: messages['service-provider-if-applicable']},
                    ...refItems, //? Add a meaningless references header for separation?
                    { data: getElementValue('locationOfIncident'), label: messages['CIPS.i18n-locationOfIncident-label']},
                    { data: getElementValue('locationType'), label: messages['CIPS.i18n-locationOfIncidentType-label']},
                    { data: values['dateComplaint'], label: messages['datetime-of-incident']},
                    { data: values['locationOther'], label: messages['location-of-incident-if-other']},
                    ...employeeItems,
                    ...witnessItems,
                    { data: getElementValue('typeOfEvidence'), label: messages['evidence-type']},

                ]
            },
            description: {
                label: messages['description-of-the-incident'],
                data: [
                    { data: values['feedbackComplaint'], label: messages['text']}
                ]
            }
        }

        return confirmationObj;
    }

    const REQUIRED_STRING = Yup.string().required(messages["common.required"]); //easier reading for validationSchema

    const initialValues = {
        firstNameComplaint : ""
        , familyNameComplaint : ""
        , clientCommunicationMethod : ""
        , clientLanguageComplaint : ""
        , emailComplaint : ""
        , phoneNumberComplaint : ""
        , address1Complaint : ""
        , address2Complaint : ""
        , countryComplaint : ""
        , cityComplaint : ""
        , postalCodeComplaint : ""
        , provinceComplaint: ""
        , thirdPartyAuthorized : []
        , typeOfComplaint : []
        , disabilityAccomodations : ""
        , previousComplaint : []
        , previousComplaintReference : ""
        , cbsaReferenceNumber : ""
        , locationOfIncident : ""
        , locationType : ""
        , dateComplaint : ""
        , locationOther : ""
        , typeOfEvidence : []
        , feedbackComplaint : ""
        , certificationComplaint : []
        , firstNameComplaintRep : ""
        , familyNameComplaintRep : ""
        , firmOrganizationComplaint : ""
        , clientCommunicationMethodRep : ""
        , clientLanguageComplaintRep : ""
        , orderCommunicationRep : ""
        , complaintEmailRep : ""
        , phoneNumberComplaintRep : ""
        , address1ComplaintRep : ""
        , cityComplaintRep : ""
        , countryComplaintRep : ""
        , provinceStateComplaintRep: ""
        , postalCodeComplaintRep : ""
        , stateComplaint: ""
        , referenceItem: []
        , serviceProvider : ""
        , employeeDetails: []
        , witnessDetails: []

        // Form filler for dev/testing - leave it commented out for commits
        //  , ...complaint
    }

    return(
        <div>
            <Spinner className="spinner" show={showSpinner} />
            {/* TODO: re-implement the WET error validation by giving the parent div className="wb-frmvld", has some errors that need fixing at a later time */}
            <div>
                <Formik
                    initialValues={initialValues}
                    //! NOTE: any changes to validation schema must be mirrored in the JSON validation file (i.e. complaint.json)
                    validationSchema={Yup.object().shape({ // Do we need the .shape()?
                        // Client details
                        firstNameComplaint: REQUIRED_STRING.matches (/^[\p{L}\p{M}\s'’.-]+$/u, messages["common.mustBeALetter"]).max(25, messages["common.maxLength.25"])
                        , familyNameComplaint: REQUIRED_STRING.matches (/^[\p{L}\p{M}\s'’.-]+$/u, messages["common.mustBeALetter"]).max(25, messages["common.maxLength.25"])
                        , clientLanguageComplaint: REQUIRED_STRING
                        , clientCommunicationMethod: REQUIRED_STRING
                        , emailComplaint: REQUIRED_STRING.matches(/^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/, messages["common.mustBeAValidEmailAddress"]).max(100, messages["common.maxLength.100"]).nullable()
                        , phoneNumberComplaint: REQUIRED_STRING.matches (telephoneRegexp, messages["contact.i18n-numberHyphensPlusSignsSpacesAndRoundBrackets"]).max(25, messages["common.maxLength.25"])

                        // 3rd party rep - only needed when 'thirdPartyAuthorized' is yes
                        , firstNameComplaintRep: Yup.string().matches (/^[\p{L}\p{M}\s'’.-]+$/u, messages["common.mustBeALetter"]).when('thirdPartyAuthorized', { is: (value) => { return value?.includes('yes')},
                            then: REQUIRED_STRING.max(25, messages["common.maxLength.25"]),
                        })
                        , familyNameComplaintRep: Yup.string().matches (/^[\p{L}\p{M}\s'’.-]+$/u, messages["common.mustBeALetter"]).when('thirdPartyAuthorized', { is: (value) => { return value?.includes('yes')},
                            then: REQUIRED_STRING.max(25, messages["common.maxLength.25"]),
                        })
                        , clientCommunicationMethodRep: Yup.string().when('thirdPartyAuthorized', { is: (value) => { return value?.includes('yes')},
                            then: REQUIRED_STRING,
                        })
                        , clientLanguageComplaintRep: Yup.string().when('thirdPartyAuthorized', { is: (value) => { return value?.includes('yes')},
                            then: REQUIRED_STRING,
                        })
                        , orderCommunicationRep: Yup.string().when('thirdPartyAuthorized', { is: (value) => { return value?.includes('yes')},
                            then: REQUIRED_STRING,
                        })

                        // Mailing address

                        , address1Complaint: REQUIRED_STRING.max(100, messages["common.maxLength.100"])
                        , address2Complaint: Yup.string().max(100, messages["common.maxLength.100"])
                        , countryComplaint: REQUIRED_STRING
                        , provinceComplaint: Yup.string().when("countryComplaint", {
                            is: (countryComplaint) => countryComplaint === constants.countriesForValidation["Canada"]
                            , then: REQUIRED_STRING
                        })
                        , stateComplaint: Yup.string().when("countryComplaint", {
                            is: (countryComplaint) => countryComplaint === constants.countriesForValidation["United States"]
                            , then: REQUIRED_STRING
                        })
                        , provinceStateComplaint: Yup.string().max(50, messages["common.maxLength.50"])
                        , cityComplaint: REQUIRED_STRING.max(100, messages["common.maxLength.100"])

                        // * This is nice-to-have but not putting in initially since it's not in current version
                        , postalCodeComplaint: Yup.string()
                            .when('countryCompliant', {
                            is: 'Canada',
                            then: Yup.string().matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, messages["common.invalidPostalCode"])
                            })
                            .when('countryCompliant', {
                            is: 'United States',
                            then: Yup.string().matches(/^\d{5}(-\d{4})?$/, messages["common.invalidZipCode"])
                            })

                        // Details
                        , typeOfComplaint: Yup.array().min(1, messages["common.required"])
                        , previousComplaintReference: Yup.string().when('previousComplaint', { is: (value) => { return value?.includes('yes')},
                            then: REQUIRED_STRING.max(25, messages["common.maxLength.25"]),
                        })
                        // Reference

                        //* This is nice-to-have but not putting in initially since it's not in current version
                        // , referenceItem: Yup.array().of(
                        //     Yup.object({
                        //         referenceType: REQUIRED_STRING
                        //     })
                        //   )
                        , referenceItem: Yup.array().of(
                            Yup.object().shape({
                                referenceNumber: Yup.string()
                                .when('referenceType', {
                                    is: 'Airway bill number [reference-number=airway_bill_number]',
                                    then: REQUIRED_STRING.matches(/^[a-zA-Z0-9]{3}-\d{8}$/, messages["enter-a-valid-reference-number"])
                                })
                                .when('referenceType', {
                                    is: 'BSF241 general receipt number [reference-number=BSF241]',
                                    then: REQUIRED_STRING.matches(/^[A-Za-z]\d{6}$/, messages["enter-a-valid-reference-number"])
                                })
                                .when('referenceType', {
                                    is: 'BSF715 casual goods accounting document [reference-number=BSF715]',
                                    then: REQUIRED_STRING.matches(/^[A-Za-z]{2}\d{9} [A-Za-z]$/, messages["enter-a-valid-reference-number"])
                                })
                                .when('referenceType', {
                                    is: 'Cargo control number [reference-number=cargo_control_number]',
                                    then: REQUIRED_STRING.matches(/^[A-Za-z0-9]{25}$/, messages["enter-a-valid-reference-number"])
                                })
                                .when('referenceType', {
                                    is: 'Cargo control number (air) [reference-number=cargo_control_number_air]',
                                    then: REQUIRED_STRING.matches(/^[A-Za-z0-9]{3}-[A-Za-z0-9]{21}$/, messages["enter-a-valid-reference-number"])
                                })
                                // That looks weird even in a live server, awaiting for David confirmation
                                .when('referenceType', {
                                    is: 'Container number [reference-number=container_number]',
                                    then: REQUIRED_STRING.matches(/^[A-Za-z]{4}\d{7}$/, messages["enter-a-valid-reference-number"])
                                })
                                .when('referenceType', {
                                    is: 'Importer number [reference-number=importer_number]',
                                    then: REQUIRED_STRING.matches(/^\d{9}[A-Za-z]{2}\d{4}$/, messages["enter-a-valid-reference-number"])
                                })
                                .when('referenceType', {
                                    is: 'Postal tracking number [reference-number=postal_tracking_number]',
                                    then: REQUIRED_STRING.matches(/^\d{4} \d{4} \d{4} \d{4}$/, messages["enter-a-valid-reference-number"])
                                })
                                .when('referenceType', {
                                    is: 'Postal tracking number (commercial) [reference-number=postal_tracking_number_commercial]',
                                    then: REQUIRED_STRING.matches(/^[A-Za-z]{2} \d{3} \d{3} \d{3} [A-Za-z]{2}$/, messages["enter-a-valid-reference-number"])
                                })
                                .when('referenceType', {
                                    is: 'Postal tracking number (international) [reference-number=postal_tracking_number_international]',
                                    then: REQUIRED_STRING.matches(/^[A-Za-z]{2}\d{9}[A-Za-z]{2}$/, messages["enter-a-valid-reference-number"])
                                })
                                .when('referenceType', {
                                    is: 'Seizure receipt [reference-number=seizure_receipt]',
                                    then: REQUIRED_STRING.matches(/^\d{4}-\d{2}-\d{4}$/, messages["enter-a-valid-reference-number"])
                                })
                                .when('referenceType', {
                                    is: 'Tracking/form/cargo number [reference-number=tracking_form_cargo_number]',
                                    then: REQUIRED_STRING.matches(/^[A-Za-z]{4}\d{11}$/, messages["enter-a-valid-reference-number"])
                                })
                                .when('referenceType', {
                                    is: 'Transaction number [reference-number=transaction_number]',
                                    then: REQUIRED_STRING.matches(/^\d{14}$/, messages["enter-a-valid-reference-number"])
                                })
                                .when('referenceType', {
                                    is: 'Unique client identifier (UCI) number [reference-number=uci]',
                                    then: REQUIRED_STRING.matches(/^(\d{2}-\d{4}-\d{4}|\d{4}-\d{4})$/, messages["enter-a-valid-reference-number"])
                                })
                            }))
                         
                        // Location
                        , locationOfIncident: REQUIRED_STRING
                        , locationType: REQUIRED_STRING
                        , dateComplaint: Yup.date().required(messages["common.required"])
                            .max(today, messages['common.dateTimeNoFuture'])
                        , feedbackComplaint: REQUIRED_STRING.max(10000, messages["common.maxLength.10000"])

                        , certificationComplaint: Yup.array().min(1, messages["common.required"])
                    })}
                    onSubmit={(values, { resetForm }) => {
                        setFlashError(null)
                        setShowSpinner(true)
                        handleSubmitComplaint(values, resetForm)
                        // console.debug(`VALUES: `, values)
                    }}
                >
                    {({ errors, isSubmitting, setSubmitting, values, setFieldValue, resetForm }) => {
                        formikSetFieldValue.current = setFieldValue;

                        if (isSubmitting && errors) {
                            setTimeout(() => { // React official workaround for setting state inside of render
                                setFlashError(null)
                                setSubmitting(false)
                            }, 0)
                            scrollToFirstError(errors);
                        }

                        return (
                            <Form className="cmxform background-light" id="feedback-frm-complaint">
                                <div className="panel panel-primary error-parent">
                                    <div className="panel-heading">
                                        <h2 className="panel-title">
                                            <FormattedMessage id="contact-information" />
                                        </h2>
                                    </div>
                                    {/* CLIENT INFO */}
                                    <div className="panel-body">
                                        <div className="row">
                                            <span><TextFieldWithLabel id="firstNameComplaint" data-testid="testFirstName" name="firstNameComplaint" wrapperClassName="form-group col-md-6" wrapperStyle={{}} maxLength="80" required={true} label={messages["CIPS.i18n-firstName"]} /></span>
                                            <TextFieldWithLabel id="familyNameComplaint" data-testid="testLastName" name="familyNameComplaint" wrapperClassName="form-group col-md-6" wrapperStyle={{}} maxLength="80" required={true} label={messages["CIPS.i18n-lastName"]} />
                                        </div>
                                        <div className="row">
                                            <SelectFieldWithLabel data-testid="commType" id="clientCommunicationMethod" name="clientCommunicationMethod" wrapperStyle={{}} wrapperClassName="form-group col-md-6" fieldClassName={{}} labelClassName="control-label" required={true} label={messages["CIPS.i18n-communicationMethod-label"]} options={[ {value:"", label: messages["CIPS.i18n-communicationMethod"]} , ...clientCommunicationMethod ]} />
                                            <SelectFieldWithLabel id="clientLanguageComplaint" name="clientLanguageComplaint" wrapperStyle={{}} wrapperClassName="form-group col-md-6" fieldClassName={{}} labelClassName="control-label" required={true} label={messages["CIPS.i18n-languagePreference-label"]} options={[ {value:"", label: messages["CIPS.i18n-languagePreference"]} , ...clientLanguage ]} />
                                        </div>
                                        <div className="row">
                                            <TextFieldWithLabel id="emailComplaint" data-testid="testEmail" name="emailComplaint" wrapperClassName="form-group col-md-6" wrapperStyle={{}} maxLength="80" required={true} label={messages["CIPS.i18n-emailAddress"]} />
                                            <TextFieldWithLabel id="phoneNumberComplaint" data-testid="testNumber" name="phoneNumberComplaint" wrapperClassName="form-group col-md-6" wrapperStyle={{}} maxLength="20" required={true} label={messages["telephone-number"]} />
                                        </div>
                                    </div>
                                </div>

                                {/* MAILING ADDRESS */}
                                <div className="panel panel-primary error-parent">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">
                                            <FormattedMessage id="mailing-address" />
                                        </h3>
                                    </div>
                                    <div className="panel-body">
                                        <div className="row">
                                            <TextFieldWithLabel data-testid="add1" id="address1Complaint" name="address1Complaint" wrapperClassName="form-group col-xs-12 col-md-12" wrapperStyle={{}}  maxLength="80" label={messages["address-street-1"]} />
                                        </div>
                                        <div className="row">
                                            <TextFieldWithLabel data-testid="add2" id="address2Complaint" name="address2Complaint" wrapperClassName="form-group col-xs-12 col-md-12" wrapperStyle={{}} maxLength="80" label={messages["address-street-2"]} />
                                        </div>
                                        <div className="row">
                                            <SelectFieldWithLabel data-testid="country" id="countryComplaint" name="countryComplaint" wrapperStyle={{}} fieldClassName={{}} wrapperClassName="form-group col-xs-12 col-md-6" labelClassName="control-label" label={messages["CIPS.i18n-country-complaint-label"]} options={[ {value:"", label: messages["CIPS.i18n-country-complaint"]} , ...countryComplaint ]} />
                                            <ProvinceStateDropdown data-testid="province" countryFieldId="countryComplaint" provinceId="provinceComplaint" stateId="stateComplaint" provinceStateComplaintId="provinceStateComplaint" provinceStateSelectedValue="provinceStateSelectedValue" provinceStateSelectedLabel="provinceStateSelectedLabel"/>
                                            <TextFieldWithLabel data-testid="city" id="cityComplaint" name="cityComplaint" wrapperClassName="form-group col-xs-12 col-md-6" wrapperStyle={{}} maxLength="80" label={messages["city"]} />
                                            <TextFieldWithLabel id="postalCodeComplaint" name="postalCodeComplaint" wrapperClassName="form-group col-xs-12 col-md-6" wrapperStyle={{}} maxLength="80" label={messages["postalzip-code"]} />
                                        </div>
                                    </div>
                                </div>

                                {/* 3RD PARTY REP */}
                                <div className="row error-parent">
                                    <CheckboxWithLabel id="thirdPartyAuthorized" name="thirdPartyAuthorized" required={true}
                                        options={[
                                            {value:"yes", required:false, label:messages["do-you-authorize-a-third"]}
                                        ]}
                                    />
                                </div>
                                <ThirdPartyRepDetails />

                                {/* COMPLAINT DETAILS */}
                                <div className="panel panel-primary error-parent">
                                    <div className="panel-heading">
                                        <h2 className="panel-title">
                                            <FormattedMessage id="details" />
                                        </h2>
                                    </div>
                                    <div className="panel-body error-parent">
                                        <TypeOfComplaint />
                                        <hr />
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="disabilityAccomodations">
                                                    <span className="field-name">
                                                        <FormattedMessage id="accommodations-related-disability" />
                                                    </span>
                                                </label>
                                                <Field as="textarea" rows="6" id="disabilityAccomodations" name="disabilityAccomodations" className="full-width form-control" maxLength={2500} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                {/* TODO: WIPE OUT PREVIOUS REF IF THIS IS UNCHECKED so values don't bother getting submitted */}
                                                <CheckboxWithLabel id="previousComplaint" name="previousComplaint" required={false}
                                                    options={[
                                                        {value:"yes", required:false, label:messages["is-this-a-previously-reported"]}
                                                    ]}
                                                />
                                                <PreviouslyReportedComplaint />
                                            </div>
                                        </div>
                                        {/* CBSA Reference Number */}
                                        <ReferenceNumberDetails />

                                        {/* Location */}
                                        <section className="panel panel-default" id="locationSection">
                                            <header className="panel-heading bold">
                                                {messages['location']}
                                            </header>
                                            <div className="panel-body">
                                                <p id="locationNote">
                                                    <FormattedMessage id="directory-directing-appropriate" values={{a: (chunks) => <a href={"https://www.cbsa-asfc.gc.ca/do-rb/menu-"+(document.documentElement.lang === "fr" ? "fra" : "eng")+".html"} target="_blank">{chunks} <span className=" glyphicon glyphicon-new-window"/></a>}} />
                                                </p>
                                                <br/>
                                                <div className="row error-parent">
                                                    <SelectFieldWithLabel data-testid="incidentLocation" id="locationOfIncident" name="locationOfIncident" wrapperStyle={{}} wrapperClassName="form-group col-md-6 marginpadding" fieldClassName={{}} labelClassName="control-label" required={true} label={messages["CIPS.i18n-locationOfIncident-label"]}
                                                        options={[
                                                            {value:"", label: messages["CIPS.i18n-locationOfIncident"]}
                                                            , ...locationOfIncident
                                                        ]}
                                                    />
                                                    {/* Text field if location chosen is 'other' */}
                                                    {values.locationOfIncident === 'Other [9999]' &&
                                                        <div className="form-group col-md-6 ">
                                                            <label htmlFor="locationOther" className="control-label">
                                                                <span className="field-name">
                                                                    <FormattedMessage id="location-of-incident-if-other" />
                                                                </span>
                                                            </label>
                                                            <Field id="locationOther" name="locationOther" className="form-control full-width" aria-describedby="location-note" />
                                                        </div>
                                                    }
                                                    <SelectFieldWithLabel data-testid="locationType" id="locationType" name="locationType" wrapperStyle={{}} wrapperClassName="form-group col-md-4 marginpadding" fieldClassName={{}} labelClassName="control-label" required={true} label={messages["CIPS.i18n-locationOfIncidentType-label"]}
                                                            options={[
                                                                {value:"", label: messages["CIPS.i18n-locationOfIncidentType"]}
                                                                , ...locationType
                                                            ]}
                                                        />

                                                    {/* Note: this isn't integrated into Formik, we hack around it by using onChange to set formik field value manually */}
                                                    <DateTimeFieldWithLabel
                                                        data-testid="incidentDate"
                                                        name="dateComplaint"
                                                        id="dateComplaint"
                                                        label={messages["datetime-of-incident"]}
                                                        min="2010-01-01"
                                                        max={today}
                                                        wrapperClassName="form-group col-md-4 standard-padding"
                                                        labelClassName="control-label"
                                                        style= {{}}
                                                        inputWrapperClassName="col-md-12 no-padding-left"
                                                        placeholder={messages['datetime-placeholder']}
                                                        required={true}
                                                    />
                                                    <div className="form-group col-md-4">
                                                        <p className="small" id="dateComplaintNote"><FormattedMessage id="incidents-complaint-approximate" values={{span: (chunks) => <span className="nowrap">{chunks}</span>, a: (chunks) => <a href={"#feedbackComplaint"}>{chunks}</a>}} />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <EmployeeDetailsDynamicRow />
                                        <WitnessDetailsDynamicRow />
                                        <div className="row ">
                                            <div className="form-group col-md-12 full-width">
                                                <label className="control-label">
                                                    <span className="field-name">
                                                        <FormattedMessage id="do-you-have-any-evidence" />
                                                    </span>
                                                </label>
                                                <br />
                                                <div className="row standard-margin">
                                                    <CheckboxWithLabel id="typeOfEvidence" name="typeOfEvidence" required={true} options={[...typeOfEvidence]} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* DESCRIPTION */}
                                        <section className="panel panel-default error-parent">
                                            <header className="panel-heading bold">
                                                <FormattedMessage id="description-of-the-incident" />
                                            </header>
                                            <div className="panel-body">
                                                <p id="feedbackNoteComplaint">
                                                    {messages['characters-information-circumstances']}
                                                </p>
                                                <TextAreaFieldWithLabel
                                                    data-testid= "feedbackComp"
                                                    id="feedbackComplaint"
                                                    name="feedbackComplaint"
                                                    wrapperClassName=""
                                                    numOfRows={5}
                                                    numOfCols={70}
                                                    maxLength="10100"
                                                    required={true}
                                                    label={messages["description"]}
                                                />
                                            </div>
                                        </section>
                                        <div className="form-group error-parent">
                                            <CheckboxWithLabel id="certificationComplaint" name="certificationComplaint" required={true} label={messages['certificationAgreement']} hideMainLabel
                                                options={[
                                                    {value:"yes", required:true, label:messages["i-certify-that-to-the"]}
                                                ]}
                                            />
                                        </div>
                                        <div style={{float:"left"}}>
                                            <button type="submit" name="submit" className="btn btn-primary" id="submitComplaintButton" value="Submit" >
                                                {messages["button.submit"]}
                                            </button>
                                        </div>
                                        <button type="reset" className="btn btn-default mrgn-lft-md">
                                            {messages["button.reset"]}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
		</div>
    )
}