import React, { useState, useEffect, useContext }  from 'react';
import { useFormikContext } from 'formik';
import { FormattedMessage, IntlContext } from 'react-intl';
import { TextFieldWithLabel, SelectFieldWithLabel, TextAreaFieldWithLabel, DateTimeFieldWithLabel, DateFieldWithLabel } from './FormFields';
import { FieldEnum, SubCategoriesToFields } from './data';
import { CommercialDataHolder } from './CommercialDataHolder';

const CommercialFields = ({ selectedProgram }) => {
  const { values, handleChange, initialValues, setFieldValue, setFieldTouched } = useFormikContext();
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const { messages } = useContext(IntlContext);
  const {
    commercialProgramOptions,
    iamOptions,
    authRepTypeOptions,
    categoryOptions,
    subcategoryOptionsForCategory,
    modeOfTransportOptions
  } = CommercialDataHolder(messages);

  const selectedSubCategoryid = values.selectedSubCategory || '';

//Get today's date in yyyy-mm-ddThh:mm for datepicker

const now = new Date();
const offset = now.getTimezoneOffset() * 60000; // Offset in milliseconds
const today = new Date(now - offset).toISOString().slice(0, 19); //


  //Business fields
  // TODO: date fields should use the react-flatpickr (see complaint form)
  const attributesMap = {
    accountSecurityNumber: { maxLength: 5, type: 'text'},
    bondNumber: { maxLength: 25, type: 'text', placeholder: messages["CIPS.i18n-numbers"]},
    businessName: { maxLength: 60, type: 'text'},
    businessNumber: { maxLength: 9, type: 'text', placeholder: messages["CIPS.i18n-numbers"]},
    cargoControlNumber: { maxLength: 40, type: 'text'},
    carrierCode: { maxLength: 4, type: 'text'},
    chequeAmount: { maxLength: 8, type: 'text', placeholder: messages["contact.i18n-numbersWithUpTo2DecimalPlaces"]},
    chequeNumber: { maxLength: 8, type: 'text'},
    conveyanceReferenceNumber: { maxLength: 40, type: 'text'},
    dailyNoticeAmount: { maxLength: 8, type: 'text', placeholder: messages["contact.i18n-numbersWithUpTo2DecimalPlaces"]},
    dailyNoticeDate: { maxLength: 10, component: 'DateTimeFieldWithLabel', type: 'date', placeholder: messages["contact.i18n-dateFormat"]},
    dateOfTransaction: { maxLength: 10, component: 'DateTimeFieldWithLabel', type: 'date', placeholder: messages["contact.i18n-dateFormat"]},
    dateOnCheque: { maxLength: 10, component: 'DateTimeFieldWithLabel', type: 'date', placeholder: messages["contact.i18n-dateFormat"]},
    modeOfTransport: { maxLength: 60, type: 'select', modeOfTransportOptions: modeOfTransportOptions },
    paymentAmount: { maxLength: 8, type: 'text', placeholder: messages["contact.i18n-numbersWithUpTo2DecimalPlaces"]},
    paymentDate: { maxLength: 10, component: 'DateTimeFieldWithLabel', placeholder: messages["contact.i18n-dateFormat"]},
    previousTicketNumber: { maxLength: 15, type: 'text'},
    rmNumber: { maxLength: 4, type: 'text', placeholder: messages["CIPS.i18n-numbers"]},
    statementOfAccountAmount: { maxLength: 8, type: 'text', placeholder: messages["contact.i18n-numbersWithUpTo2DecimalPlaces"]},
    statementOfAccountDate: { maxLength: 10, component: 'DateTimeFieldWithLabel', placeholder: messages["contact.i18n-dateFormat"]},
    subOfficeLocation: { maxLength: 4, type: 'text', placeholder: messages["CIPS.i18n-numbers"]},
    transactionAmount: { maxLength: 8, type: 'text', placeholder: messages["contact.i18n-numbersWithUpTo2DecimalPlaces"]},
    transactionNumber: { maxLength: 14, type: 'text', placeholder: messages["CIPS.i18n-numbers"]},
  };

  const getFieldsForSubCategory = (subCategoryId) => {
    const subCategoryConfig = SubCategoriesToFields[subCategoryId];

    if (!subCategoryConfig) {
        console.log("No config or empty config found for:", subCategoryId);
        return [];
    }

    const allFields = [];

    // Add required fields to the allFields array
    subCategoryConfig.requiredFields.forEach(fieldObj => {
        const fieldKey = fieldObj.fieldId;
        // console.log("Checking required field:", fieldKey, "Exists in FieldEnum:", FieldEnum.hasOwnProperty(fieldKey));
        if (FieldEnum.hasOwnProperty(fieldKey)) {
            allFields.push({
                fieldId: FieldEnum[fieldKey].fieldId,
                required: true
            });
        }
    });

    // Add optional fields to the allFields array
    subCategoryConfig.optionalFields.forEach(fieldObj => {
        const fieldKey = fieldObj.fieldId;
        // console.log("Checking optional field:", fieldKey, "Exists in FieldEnum:", FieldEnum.hasOwnProperty(fieldKey));
        if (FieldEnum.hasOwnProperty(fieldKey)) {
            allFields.push({
                fieldId: FieldEnum[fieldKey].fieldId,
                required: false
            });
        }
    });

    return allFields;
  };

  //Reset fields if user changes topics (stops form from getting out of sync and showing non-relevant sub-options)
  useEffect(() => {
    const fieldsToReset = ['commercialProgram', 'iam', 'authRepType', 'category-bistier2', 'selectedSubCategory', 'detailedDescription']
    fieldsToReset.forEach(field => {
      setFieldValue(field, initialValues[field])
      setFieldTouched(field, false)
    })

    // TODO: below code will also clear out the business fields but it visibly slows down changing topics so doesn't seem worth it for now
    // Object.keys(attributesMap).forEach(key => {
    //   setFieldValue(key, initialValues[key])
    //   setFieldTouched(key, false)
    // })
  },[values['questionTopic']])

   // Update subcategory options based on the selected category
  useEffect(() => {
    const bistier2 = values['category-bistier2'];
    const options = subcategoryOptionsForCategory[bistier2];

    if (Array.isArray(options)) {
      setSubcategoryOptions(options);
    }

    //Reset selected category (for when it already has a value but the parent subcategory gets changed)
    setFieldValue('selectedSubCategory', '')
    setFieldTouched('selectedSubCategory', false)

  }, [values['category-bistier2']]);

  useEffect(() => {
    // Clear topic to close up options when user switches topics
    setFieldValue('category-bistier2', initialValues['category-bistier2'])
    setFieldTouched('category-bistier2', false, false)
  }, [selectedProgram]);

  const fieldsToRender = getFieldsForSubCategory(selectedSubCategoryid);

  return (
      <fieldset className="col-md-12">
        <div className="row error-parent">
          {/* Commercial program */}
          <SelectFieldWithLabel
            data-testid="commercialProgram"
            id="commercialProgram"
            name="commercialProgram"
            wrapperClassName="form-group col-md-12"
            required
            label={ messages["contact.i18n-whatCommercialProgramAreYouInquiryingAbout"] }
            options={[
              { value: "", label: messages["contact.i18n-selectFromTheList"] },
              ...commercialProgramOptions,
            ]}
          />
          {/* I am... (employee, third party) */}
          <SelectFieldWithLabel
            data-testid="iam"
            id="iam"
            name="iam"
            wrapperClassName="form-group col-md-12"
            required
            label={ messages["contact.i18n-withRespectToTheCompanyThisQuestionIsFor_IAm"] }
            options={[
              { value: "", label: messages["contact.i18n-selectFromTheList"] },
              ...iamOptions,
            ]}
          />

          {/* Third party type - conditional on above  */}
          {values.iam === 'An authorized representative of the company [iam=888060000]' &&
            <SelectFieldWithLabel
              id="authRepType"
              name="authRepType"
              wrapperClassName="form-group col-md-12"
              required
              label={ messages["contact.i18n-authorizedRepresentativeType"] }
              options={[
                { value: "", label: messages["contact.i18n-selectFromTheList"] },
                ...authRepTypeOptions,
              ]}
            />
          }

          {/*  Appropriate topic - dependent on inquiry option 2-5 */}
          {selectedProgram && (
            <SelectFieldWithLabel
              data-testid="bistier2"
              id="category-bistier2"
              name="category-bistier2"
              wrapperClassName="form-group col-md-12"
              required
              label={messages["contact.i18n-category"]}
              options={[
                { value: "", label: "" },
                ...Array.isArray(categoryOptions[selectedProgram]) ? categoryOptions[selectedProgram] : []
              ]}
              onChange={handleChange} // Use Formik's handleChange
            />
          )}

          {/*  Subcategories of dropdown depending on your choice above for category */}
          {values["category-bistier2"] && ( //popsup depending on the value in category-bistier2
            <SelectFieldWithLabel
              data-testid="selectedSubCategory"
              id="selectedSubCategory"
              name="selectedSubCategory"
              wrapperClassName="form-group col-md-12"
              required
              label={messages["contact.i18n-subCategory"]}
              options={[{ value: "", label: "" }, ...(Array.isArray(subcategoryOptions) ? subcategoryOptions : [])]}
              onChange={handleChange}
            />
          )}

          {/* BUSINESS INFORMATION */}

          {values["category-bistier2"] && (
            <h3>
              <FormattedMessage id="contact.i18n-buisnessInformation" />
            </h3>
          )}
          <div className="error-parent">
            {values['category-bistier2'] && fieldsToRender.map(field => {
              const { type, maxLength, placeholder, component  } = attributesMap[field.fieldId] ?? { type: 'text' }
              const fieldProps = {
                id: field.fieldId,
                name: `${field.fieldId}`,
                label: messages[`contact.i18n-${field.fieldId}`],
                required: field.required,
                maxLength: maxLength,
                placeholder: placeholder,
                options: type !== 'select' ? null :
                  [
                    { value: "", label: "" },
                    ...modeOfTransportOptions,
                  ],
                onChange: (e) => {
                  handleChange(e);
                },
                ...(field.fieldId === 'businessName' && { 'data-testid': 'businessName' }),
              };

              return (
                <div key={field.fieldId} className="form-group col-md-12">
                  {type === 'select' && <SelectFieldWithLabel options={modeOfTransportOptions} {...fieldProps} />}
                  {type === 'text' && <TextFieldWithLabel {...fieldProps} />}
                  {type === 'date' ? (
                    <DateTimeFieldWithLabel
                    data-testid="testdate"
                    name={field.fieldId}
                    id={field.fieldId}
                    label={messages[`contact.i18n-${field.fieldId}`]}
                    min="2010-01-01"
                    max={today}
                    wrapperClassName="form-group col-md-4 standard-padding"
                    labelClassName="control-label"
                    style={{}}
                    inputWrapperClassName="col-md-12 no-padding-left"
                    placeholder={messages['datetime-placeholder']}
                    required={field.required}
                    onlyDate={true}
                  />
                  ) : null}
                </div>
              );
            })}
          </div>

          {/* DETAILED DESCRIPTION TEXT */}
          {/* TODO: make sure this looks ok, re-format? */}
          <TextAreaFieldWithLabel
            id="detailedDescription"
            name="detailedDescription"
            label={`
                ${messages["contact.i18n-pleaseProvideADetailedDescriptionOfYourRequestForAssistance"]} (${messages["contact.i18n-pleaseProvideADetailedDescriptionOfYourRequestForAssistance-restrictions"]}):
            `}
            required
            maxLength="3000"
            numOfRows="4"
            wrapperClassName={{}}
          />
        </div>
      </fieldset>
  );
};

export default CommercialFields;
