import React, {useState, useContext, useEffect } from 'react';
import {FormattedMessage, IntlContext} from 'react-intl';

import axios from 'axios';
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import Spinner from '../../../components/Spinner';
import { SelectFieldWithLabel, TextFieldWithLabel, TextAreaFieldWithLabel, CheckboxWithLabel } from '../../../components/FormFields';
import { getConstants, FormTypes } from '../../../constants';

import { postConfigHeader, scrollToFirstError, getElementValue } from '../../../utils/formUtils';
import { compliment } from '../../formFiller';

export default function ComplimentForm({navigation}) {
    const [showSpinner, setShowSpinner] = useState(false);
    const [disableFields, setDisableFields] = useState(false);
	const { messages } = useContext(IntlContext);
    const setFlashError = navigation?.setFlashError;

	const clientLanguage = getConstants(messages)?.["clientLanguage"]
    const telephoneRegexp = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/

    useEffect(() => {
		setFlashError(null);
        window.scrollTo(0, 0); // Workaround for screen rendering at the bottom of the page
	},[])

    function handleSubmitCompliment(values, resetForm) {
		// console.debug('VALUES',values);

		let submitObj = {
            firstNameCompliment: values["firstNameCompliment"],
            familyNameCompliment: values["familyNameCompliment"],
            clientLanguageCompliment: values["clientLanguageCompliment"],
            emailCompliment: values["emailCompliment"],
            phoneNumberCompliment: values["phoneNumberCompliment"],
            feedbackCompliment: values["feedbackCompliment"],
            nirobat: "1"
        }
		// console.debug('SUBMIT OBJ', submitObj);

        //Post form
		axios.post(window.env.apiUrlPrefix + "/compliment", submitObj, postConfigHeader).then(function(response){
			// console.debug("Response status:", response?.status, response);

            const confirmationObj = {
                compliment: {
                    label: messages['CIPS.i18n-feedbackType-compliment'],
                    data: [
                        { data: values["firstNameCompliment"], label: messages['CIPS.i18n-firstName']},
                        { data: values["familyNameCompliment"], label: messages['CIPS.i18n-lastName']},
                        { data: getElementValue("clientLanguageCompliment"), label: messages['CIPS.i18n-languagePreference-label']},
                        { data: values["emailCompliment"], label: messages['CIPS.i18n-emailAddress']},
                        { data: values["phoneNumberCompliment"], label: messages['telephone-number']},
                        { data: values["feedbackCompliment"], label: messages['your-feedback']},
                    ]
                }
            }

            const confirmationPreamble = <>
                <h2>{messages['confirmation']}</h2>
                <div>{messages['contact.i18n-confirm-compliment-preamble']}</div>
            </>
			resetForm();
            navigation.navigate("Results", { results: confirmationObj, preamble: confirmationPreamble, form: FormTypes.COMPLIMENT});
		}).catch(function(error){
			console.error("POST ERROR", error);
            setFlashError({duration:-1, messages:[`${messages['http.general']} (${error.code})`]})
            setDisableFields(false);
            setShowSpinner(false)
		})
	}

    return(
        // TODO: re-implement the WET error validation by giving the parent div className="wb-frmvld", has some errors that need fixing at a later time
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    firstNameCompliment: ""
                    , familyNameCompliment: ""
                    , clientLanguageCompliment: ""
                    , emailCompliment: ""
                    , phoneNumberCompliment: ""
                    , feedbackCompliment: ""
                    , certificationCompliment: []

                    // Form filler for dev/testing - leave it commented out for commits
                    // , ...compliment
                }}
                validationSchema={Yup.object({
                    firstNameCompliment: Yup.string().required(messages["common.required"]).matches(/^[\p{L}\p{M}\s'’.-]+$/u, messages["common.mustBeALetter"]).max(25, messages["common.maxLength.25"])
                    , familyNameCompliment: Yup.string().required(messages["common.required"]).matches (/^[\p{L}\p{M}\s'’.-]+$/u, messages["common.mustBeALetter"]).max(25, messages["common.maxLength.25"])
                    , clientLanguageCompliment: Yup.string().required(messages["common.required"])
                    , emailCompliment: Yup.string().required(messages["common.required"]).matches(/^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/, messages["common.mustBeAValidEmailAddress"]).max(100, messages["common.maxLength.100"]).nullable()
                    , phoneNumberCompliment: Yup.string().matches(telephoneRegexp, messages["contact.i18n-numberHyphensPlusSignsSpacesAndRoundBrackets"]).max(17, messages["common.maxLength.17"])
                    , feedbackCompliment: Yup.string().required(messages["common.required"]).max(10000, messages["common.maxLength.10000"])
                    , certificationCompliment: Yup.array()
                        .required(messages["common.required"])  // Ensures the array itself is not empty or undefined
                        .min(1, messages["common.required"])    // Ensures at least one item is present
                        .max(1, messages["common.required"])    // Ensures no more than one item is present
                        .of(Yup.string().oneOf(['yes'], messages["common.required"])) // Ensures the item equals 'yes'
                })}
                onSubmit={(values, { resetForm }) => {
                    setFlashError(null)
                    setDisableFields(true);
                    setShowSpinner(true)
                    handleSubmitCompliment(values, resetForm);
                }}
            >
                {({ errors, isSubmitting, setSubmitting }) => {

                    if (isSubmitting && errors) {
                        setTimeout(() => { // React official workaround for setting state inside of render
                            setFlashError(null)
                            setSubmitting(false)
                        }, 0)
                        scrollToFirstError(errors);
                    }

                    return (
                        <>
                            <Spinner data-testid="spinner" className="spinner" show={showSpinner} />
                            <Form className="cmxform background-light" id="feedback-frm-Compliment">
                                <div className="panel panel-primary error-parent">
                                    <div className="panel-heading ">
                                        <h2 className="panel-title">
                                            <FormattedMessage id="contact-information" />
                                        </h2>
                                    </div>
                                    <div className="panel-body">
                                        <div className="row">
                                            <TextFieldWithLabel id="firstNameCompliment" name="firstNameCompliment" wrapperClassName="form-group col-md-6" wrapperStyle={{}} maxLength="80" required={true} label={messages["CIPS.i18n-firstName"]} disabled={disableFields} />
                                            <TextFieldWithLabel id="familyNameCompliment" name="familyNameCompliment" wrapperClassName="form-group col-md-6" wrapperStyle={{}} maxLength="80" required={true} label={messages["CIPS.i18n-lastName"]} disabled={disableFields} />
                                        </div>
                                        <div className="row">
                                            <SelectFieldWithLabel id="clientLanguageCompliment" name="clientLanguageCompliment" wrapperStyle={{}} wrapperClassName="form-group col-md-6" fieldClassName={{}} labelClassName="control-label" required={true} label={messages["CIPS.i18n-languagePreference-label"]} disabled={disableFields}
                                                options={[
                                                    {value:"", label: messages["CIPS.i18n-languagePreference"]} ,
                                                    ...clientLanguage
                                                ]}
                                            />
                                            <TextFieldWithLabel id="emailCompliment" name="emailCompliment" wrapperClassName="form-group col-md-6" wrapperStyle={{}} maxLength="80" required={true} label={messages["CIPS.i18n-emailAddress"]} disabled={disableFields} />
                                        </div>
                                        <div className="row">
                                            <TextFieldWithLabel id="phoneNumberCompliment" name="phoneNumberCompliment" wrapperClassName="form-group col-md-6" wrapperStyle={{}} maxLength="30" required={false} label={messages["telephone-number"]} disabled={disableFields} />
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-primary error-parent">
                                    <div className="panel-heading">
                                        <h2 className="panel-title required">
                                            <FormattedMessage id="your-feedback" />
                                        </h2>
                                    </div>
                                    <div className="panel-body">
                                        <p id="feedback-note-Compliment">
                                            <FormattedMessage id="characters-information-circumstances" />
                                        </p>
                                        <TextAreaFieldWithLabel id="feedbackCompliment" name="feedbackCompliment" wrapperClassName="" numOfRows={25} numOfCols={70} maxLength="10100" required={true} label={messages["feedback"]} disabled={disableFields} />
                                    </div>
                                </div>
                                <div className="error-parent">
                                    <div className="form-group">
                                        <CheckboxWithLabel id="certificationCompliment" name="certificationCompliment" required={true} disabled={disableFields} label={messages['certificationAgreement']} hideMainLabel
                                            options={[
                                                {value:"yes", required:true, label:messages["i-certify-that-to-the"]}
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div style={{float:"left"}}>
                                    <input type="hidden" name="nirobat" id="nirobat" value="" />
                                    <button data-testid="complimentSubmit" type="submit" name="submit" className="btn btn-primary">
                                        {messages["button.submit"]}
                                    </button>
                                </div>
                                <button type="reset" className="btn btn-default mrgn-lft-md">
                                    {messages["button.reset"]}
                                </button>
                            </Form>
                        </>

                    )
                }}
            </Formik>
        </div>
    )
}